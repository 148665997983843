import React, { useEffect } from 'react';

const DynamicsFormAndTracking = () => {
  useEffect(() => {
    // Function to dynamically load external scripts
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = () => resolve(script);
        script.onerror = () => reject(new Error(`Script load error for ${src}`));
        document.body.appendChild(script);
      });
    };

    // Load FormLoader.bundle.js
    loadScript('https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/eur/FormLoader/FormLoader.bundle.js');
  }, []);

  return (
    <div>
      <div
        data-form-id='377fb3a9-5fb3-ef11-b8e9-6045bddd8a24'
        data-form-api-url='https://public-eur.mkt.dynamics.com/api/v1.0/orgs/7d53da9d-b3c1-ee11-9073-000d3a67cc76/landingpageforms'
        data-cached-form-url='https://assets-eur.mkt.dynamics.com/7d53da9d-b3c1-ee11-9073-000d3a67cc76/digitalassets/forms/377fb3a9-5fb3-ef11-b8e9-6045bddd8a24'
      ></div>
      <script>document.documentElement.lang = navigator.language;</script>
    </div>
  );
};

export default DynamicsFormAndTracking;
