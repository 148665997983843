import React, { useEffect } from 'react';

const CustomFormComponent = () => {
  useEffect(() => {
    // Function to dynamically load a script
    const loadScript = (src) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
    };

    // Load the new FormLoader script
    loadScript('https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/eur/FormLoader/FormLoader.bundle.js');
  }, []);

  return (
    <>
      <div
        data-form-id='38eae5d9-b9e4-ef11-8eea-7c1e52351008'
        data-form-api-url='https://public-eur.mkt.dynamics.com/api/v1.0/orgs/7d53da9d-b3c1-ee11-9073-000d3a67cc76/landingpageforms'
        data-cached-form-url='https://assets-eur.mkt.dynamics.com/7d53da9d-b3c1-ee11-9073-000d3a67cc76/digitalassets/forms/38eae5d9-b9e4-ef11-8eea-7c1e52351008'
      ></div>
      <script>
        {`document.documentElement.lang = navigator.language;`}
      </script>
    </>
  );
};

export default CustomFormComponent;
