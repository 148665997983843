import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from "@mui/material";

/**
 * If you need to load the form script dynamically,
 * you can do so here. We'll call it once in a top-level useEffect.
 */
const loadFormScript = (src) => {
  return new Promise((resolve) => {
    const existingScript = document.querySelector(`script[src="${src}"]`);
    if (existingScript) {
      // Already loaded
      return resolve();
    }

    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = resolve;
    document.body.appendChild(script);
  });
};

const CustomFormComponent = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [formKey, setFormKey] = useState(0);

  useEffect(() => {
    // Load the FormLoader script once on mount
    loadFormScript('https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/eur/FormLoader/FormLoader.bundle.js');
  }, []);

  useEffect(() => {
    // Each time the tab changes, increment formKey to force a re-mount.
    setFormKey((prev) => prev + 1);
  }, [selectedTab]);

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={(e, newValue) => setSelectedTab(newValue)}
      >
        <Tab label="EN" />
        <Tab label="DE" />
        <Tab label="FR" />
        <Tab label="ES" />
      </Tabs>

      {/**
       * We add the key prop on the containing <div> so it re-mounts
       * each time formKey changes, letting the form script initialize fresh.
       */}
      <div key={formKey} id="form-container">
        {selectedTab === 0 && (
          <div
            data-form-id="abf48e9c-c9e4-ef11-9341-7c1e52879239"
            data-form-api-url="https://public-eur.mkt.dynamics.com/api/v1.0/orgs/7d53da9d-b3c1-ee11-9073-000d3a67cc76/landingpageforms"
            data-cached-form-url="https://assets-eur.mkt.dynamics.com/7d53da9d-b3c1-ee11-9073-000d3a67cc76/digitalassets/forms/abf48e9c-c9e4-ef11-9341-7c1e52879239"
          />
        )}
        {selectedTab === 1 && (
          <div
            data-form-id="18ffc9cf-cae4-ef11-9341-7c1e52879239"
            data-form-api-url="https://public-eur.mkt.dynamics.com/api/v1.0/orgs/7d53da9d-b3c1-ee11-9073-000d3a67cc76/landingpageforms"
            data-cached-form-url="https://assets-eur.mkt.dynamics.com/7d53da9d-b3c1-ee11-9073-000d3a67cc76/digitalassets/forms/18ffc9cf-cae4-ef11-9341-7c1e52879239"
          />
        )}
        {selectedTab === 2 && (
          <div
            data-form-id="f79edafb-cbe4-ef11-9341-7c1e52879239"
            data-form-api-url="https://public-eur.mkt.dynamics.com/api/v1.0/orgs/7d53da9d-b3c1-ee11-9073-000d3a67cc76/landingpageforms"
            data-cached-form-url="https://assets-eur.mkt.dynamics.com/7d53da9d-b3c1-ee11-9073-000d3a67cc76/digitalassets/forms/f79edafb-cbe4-ef11-9341-7c1e52879239"
          />
        )}
        {selectedTab === 3 && (
          <div
            data-form-id="d58b4cc8-cce4-ef11-8ee9-7c1e527668f5"
            data-form-api-url="https://public-eur.mkt.dynamics.com/api/v1.0/orgs/7d53da9d-b3c1-ee11-9073-000d3a67cc76/landingpageforms"
            data-cached-form-url="https://assets-eur.mkt.dynamics.com/7d53da9d-b3c1-ee11-9073-000d3a67cc76/digitalassets/forms/d58b4cc8-cce4-ef11-8ee9-7c1e527668f5"
          />
        )}
      </div>

      <script>
        {`document.documentElement.lang = navigator.language;`}
      </script>
    </>
  );
};

export default CustomFormComponent;
