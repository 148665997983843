import React, { useEffect } from 'react';

const CustomFormComponent = () => {
  useEffect(() => {
    // Function to dynamically load a script
    const loadScript = (src) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
    };

    // Load the FormLoader script
    loadScript('https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/eur/FormLoader/FormLoader.bundle.js');

    // Set the document language based on the user's browser
    const setLangScript = document.createElement('script');
    setLangScript.textContent = "document.documentElement.lang = navigator.language;";
    document.body.appendChild(setLangScript);
  }, []);

  return (
    <>
      <div
        data-form-id='b5eef9f0-86e1-ef11-8eea-000d3ad8fa57'
        data-form-api-url='https://public-eur.mkt.dynamics.com/api/v1.0/orgs/7d53da9d-b3c1-ee11-9073-000d3a67cc76/landingpageforms'
        data-cached-form-url='https://assets-eur.mkt.dynamics.com/7d53da9d-b3c1-ee11-9073-000d3a67cc76/digitalassets/forms/b5eef9f0-86e1-ef11-8eea-000d3ad8fa57'
      ></div>
    </>
  );
};

export default CustomFormComponent;